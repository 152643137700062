import { parseISO, subYears } from 'date-fns'
import type { SelectOption } from 'naive-ui'

export const CONJUNCTION_TYPES = [
  'AND',
  'OR',
] as const
const makeArray = (input: string | string[]) => (Array.isArray(input) ? input : [input])

const stringMethods = {
  equals: (input: string) => ({ method: 'equals', value: input }),
  contains: (input: string) => ({ method: 'contains', value: input }),
}
const enumMethods = {
  equalsOneOf: (input: string[] | string) => ({ method: 'in', value: makeArray(input) }),
  notOneOf: (input: string[] | string) => ({ method: 'notIn', value: makeArray(input) }),
}
const enumIdMethods = {
  equalsOneOf: (input: string[] | string) => ({ method: 'some', value: { id: { in: makeArray(input) } } }),
  notOneOf: (input: string[] | string) => ({ method: 'none', value: { id: { in: makeArray(input) } } }),
}
const dateMethods = {
  // age
  lteYearsOld: (input: string) => ({ method: 'gte', value: subYears(new Date(), Number.parseInt(input)).toISOString() }),
  gteYearsOld: (input: string) => ({ method: 'lte', value: subYears(new Date(), Number.parseInt(input)).toISOString() }),
  // before/after
  lteIsoDate: (input: string) => ({ method: 'lte', value: parseISO(input).toISOString() }),
  gteIsoDate: (input: string) => ({ method: 'gte', value: parseISO(input).toISOString() }),
  dateRange: (input: [number, number]) => ([
    { method: 'gte', value: new Date(input[0]).toISOString() },
    { method: 'lte', value: new Date(input[1]).toISOString() },
  ]),
}
const relationMethods = {
  some: () => ({ method: 'some', value: { } }),
  none: () => ({ method: 'none', value: { } }),
}
const valuePresenceMethods = {
  hasValue: () => ({ method: 'not', value: { equals: null } }),
  noValue: () => ({ method: 'equals', value: null }),
}
const booleanMethods = {
  isTrue: () => ({ method: 'equals', value: true }),
  isFalse: () => ({ method: 'equals', value: false }),
}
export const METHOD_TYPES = {
  string: stringMethods,
  enum: enumMethods,
  // Use enumId for one-to-many relations
  enumId: enumIdMethods,
  date: dateMethods,
  relation: relationMethods,
  valuePresence: valuePresenceMethods,
  boolean: booleanMethods,
} as const

type MethodType = keyof typeof METHOD_TYPES
type MethodKeys = {
  [T in MethodType]: keyof typeof METHOD_TYPES[T]
}

type FilterConfigItem = {
  [T in MethodType]: {
    pathLabel: string
    methodType: T
    disabledFieldTypes?: MethodKeys[T][]
    disableInput?: boolean
    translationPrefix?: string
  } & (object extends { options?: string[] | SelectOption[] }
    ? { options?: string[] | SelectOption[], optionMultiSelect?: boolean }
    : Record<string, never>)
}[MethodType]

export type FilterConfig = Record<string, FilterConfigItem>
